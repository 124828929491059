<template>
  <div class="sticky top-0 left-0 right-0 w-full">
    <div
      class="
        flex flex-row
        justify-between
        items-center
        bg-turkis
        w-full
        h-full
        px-3
        py-3
        shadow-lg
      "
    >
    <div class="flex flex-row space-x-4 items-center">
    <div class="flex flex-row space-x-4 items-center">
      <img class="h-10 w-10" src="@/assets/cph_logo.png" />
        <div class="text-xl font-semibold">Cevi Pfadi Huus Verein</div>
    </div>
      <div class="hidden md:flex md:flex-row space-x-4 items-center justify-start">
        <MenuItem href="">Home</MenuItem>
        <MenuItem href="zweck">Zweck</MenuItem>
        <MenuItem href="aktivitaeten">Aktivitäten</MenuItem>
        <MenuItem href="vorstand">Vorstand</MenuItem>
        <MenuItem href="geschichte">Geschichte</MenuItem>
        <MenuItem href="kontakt">Kontakt</MenuItem>
      </div>
      </div>
      <MenuButton :isOpen="menuStatus" @menuStatus="handleMenuStatus" />
      <a href="#spenden" class="hidden md:block">
      <button class="bg-grassgreen rounded-lg p-3">
        Spenden oder Mitglied werden!
      </button>
      </a>
    </div>
    <MobileMenu :isOpen="menuStatus" @menuStatus="handleMenuStatus" />
  </div>
  <img
    class="sticky -z-20 top-0 left-0 right-0 w-full"
    src="@/assets/images/new_header.jpg"
  />
  <div id="zweck" class="flex flex-col w-full px-5 md:px-12 py-10 bg-turkis">
    <BigTitle>
      Willkommen auf der Webseite des Cevi-Pfadi-Huus Vereins
    </BigTitle>
    <p class="font-light text-xl">
      Das Cevi-Pfadi Haus, auch bekannt als CPH bzw. PCH, ist das Zuhause der
      beiden Jugendorganisationen Cevi Volketswil & Pfadi Wildert. Im Haus
      werden wöchentlich die Aktivitäten für die Kids am Samstag vorbereitet. So
      werden Ideen auf Papier gebracht, unvergessliche Lager geplant und
      Freundschaften geknüpft. Zudem bietet das Haus den Pfadis und Cevis
      Unterschlupf, wenn das Wetter am Wochenende mal nicht mitspielen will.<br />
      Das Haus gehört zu den Liegenschaften der Gemeinde Volketswil, verwaltet
      wird es jedoch durch unseren Verein. Wir kümmern uns um alles rund ums
      Haus, damit die Pfadis & Cevis es für ihren regulären Betrieb nutzten
      können. So koordinieren wir die permanenten Unterhaltungsarbeiten am Haus,
      organisieren alljährlich einen Putztag und treffen uns regelmässig zu
      administrativen Sitzungen. Weiter deckt der Verein die anfallenden Strom-
      & Wasserkosten, kommt für die Materialschopfmiete auf und bezahlt kleinere
      Reparaturen. Und genau dafür brauchen wir Dich! Ab 30 Franken pro Jahr
      bist Du dabei! Als Mitglied leistest man ein grosser Beitrag zur Erhaltung
      des Cevi-Pfadi-Huus. Hilf uns dabei, das Haus in Stand zu halten! Anmelden
      kann man sich weiter unten auf der Webseite!<br />
      Vielen Herzlichen Dank,<br />
      Der Cevi-Pfadi-Huus Verein im Namen aller Pfadis & Cevis
    </p>
  </div>
  <div id="spenden" class="flex flex-col w-full px-5 md:px-12 py-10 bg-grassgreen">
    <BigTitle> Spenden oder Mitglied werden </BigTitle>
    <p class="font-light text-xl">
      Nach dem ausfüllen dieses Formulars erhälst du ein E-Mail mit einem
      Einzahlungsschein um zu Spenden oder den Mitgliederbeitrag zu bezahlen.
      Falls du die Mitgliedschaft wählst, bekommst du jedes Jahr einen
      Einzahlungsschein.
    </p>
    <div class="mt-2">
      <DonationForm/>
    </div>
  </div>
  <img
    class="sticky -z-10 top-0 left-0 right-0 w-full"
    src="@/assets/images/picture_1.jpg"
  />
  <div id="aktivitaeten" class="flex flex-col w-full px-5 md:px-12 py-10 bg-brown">
    <BigTitle>Anlässe</BigTitle>
    <SmallTitle>Putztag</SmallTitle>
    <p class="font-light text-xl">
      Jährlich kommen die Pfadis und Cevis mit uns zusammen. Dabei werden Besen,
      Staubsauger, WC.-Bürsteli und Putzlappen zur Hand genommen und das Haus
      wieder auf Hochglanz poliert. Auch rund ums Haus wird jeweils Hand
      angelegt, so werden die Bäume & Sträucher zurückgeschnitten, die Umgebung
      «gefötzelet» und der Rasen gemäht.
    </p>
    <SmallTitle>GV</SmallTitle>
    <p class="font-light text-xl">
      Einmal im Jahr laden wir all unsere Mitglieder zu einer Generalversammlung
      ein. Dabei wird das Jahr reflektiert, in die Finanzen des Vereins geschaut
      und andere wichtige Traktanden besprochen. Kuchen und Kaffee lassen den
      administrativen Abend gemütlich ausklingen.
    </p>
    <SmallTitle>Vorstandssitungen</SmallTitle>
    <p class="font-light text-xl">
      Mindestens zweimal im Jahr trifft sich der Vorstand des Vereins mit den
      Vertreterinnen der Cevi und Pfadi. Beim Hausrundgang wird der Zustand des
      Hauses unter die Lupe genommen und nötige Reparaturen oder Aufräumaktionen
      in Gange geleitet. Zudem werden wichtige administrative Traktanden
      besprochen.
    </p>
  </div>
  <div id="vorstand" class="flex flex-col w-full px-5 md:px-12 py-10 bg-grayisch">
    <BigTitle>Vorstand</BigTitle>
    <PeopleGrid />
  </div>
  <div id="geschichte" class="flex flex-col w-full px-5 md:px-12 py-10 bg-turkis">
    <BigTitle>Geschichte</BigTitle>
    <p class="font-light text-xl">
      Die Idee für den Bau des „Cevi-Pfadi-Huus“ stammte aus den frühen 80er
      Jahren. Sowohl der Cevi wie auch die Pfadi kämpften stetig mit
      Platzproblemen für ihre Zusammenkünfte. Elternvertreter, Leiterinnen und
      Leiter gelangten deshalb mit der Idee eines Hausbaus an den Gemeinderat.
      Im August 1983 wurde eine Projektstudie eingereicht und bereits im
      September im Gemeinderat besprochen.
    </p>
    <p class="font-light text-xl mt-3">
      Im Januar 1984 wurde der Verein „Cevi-Pfadi-Huus“ gegründet, mit dem Ziel
      den Betrieb des Hauses sicherzustellen. Die Gemeindeversammlung am 21.
      September 1984 stimmte dem Bauvorhaben in der Höhe von 700'000.- zu. Der
      Verein musste davon 150'000.- finanzieren und konnte ausserdem während der
      Bauzeit auf viel Frondienst und grosszügige Rabatte zählen. Am 1. November
      1985 wurde das Haus von Cevi- und Pfadikindern in Beschlag genommen. Unter
      Beisein von Regierungsrätin Hedi Lang und weiteren 60 geladenen Gästen
      fand dann die offizielle Einweihung des Hauses im März 1986 statt. In den
      vielen Jahren wurde das Haus rege von Cevi- und Pfadikindern für ihr
      Programm benutzt, vor allem bei Regenwetter oder in der kalten Jahreszeit.
      Es fanden auch unzählige Vorbereitungshöcks der Leiterinnen und Leiter
      statt. Noch immer ist das Haus, dank den vielen kleineren
      Unterhaltsleistungen durch den Hausverwalter, beauftragte Handwerker und
      der Unterstützung der Gemeinde Volketswil in einem ansprechenden Zustand.
    </p>
    <p class="font-light text-xl mt-3">
      Für die Pfadi- und Cevi-Lager benötigen beide Abteilungen grosse Mengen an
      Material, wie Zelte, Blachen, Kochkessel und Verkleidungen. Dieses
      Material muss aber nicht nur gelagert, sondern auch getrocknet und geputzt
      werden können. Nachdem die Gemeinde Volketswil ihr neues Feuerwehrgebäude
      und den neuen Werkhof im Einsatz haben, wurde ab 2011 der grosse Schopf
      direkt neben dem Cevi-Pfadi-Huus nicht länger als Gemeinde-Lagerraum
      benötigt. So konnte 2012 mit der Unterstützung der
      Liegenschaften-Abteilung der Gemeinde Volketswil ein Nutzungskonzept als
      Materiallager für den Cevi und die Pfadi mit vier grossen Räumen erstellt
      werden. Der Gemeinderat gab Mitte 2012 „grünes Licht“ und so konnte der
      Verein einen Mietvertrag abschliessen sowie eine Baueingabe machen.<br />
      Nach umfangreichen Vorarbeiten konnte im November 2012 mit vielen
      Freiwilligen sowie unter fachlicher Anleitung und grosser Unterstützung
      mehrerer Handwerksfirmen aus Volketswil die neuen Lagerräume in den Schopf
      eingebaut werden. Der Schopf verfügt auch über ein grosses Rolltor, so
      dass mit einem Fahrzeug direkt hineingefahren werden kann. Die
      unmittelbare Nähe zum „Cevi-Pfadi-Huus“ bedeutet den beiden
      Jugendorganisationen viel Zeitersparnis und mehr Stauraum.
    </p>
  </div>
  <div id="kontakt" class="flex flex-col w-full px-5 md:px-12 py-10 bg-grassgreen">
    <BigTitle>Kontakt</BigTitle>
    <ContactForm/>
  </div>
  <div class="flex flex-row justify-end w-full px-5 md:px-12 py-10 text-center bg-gray-900 text-white">
    <div class="w-1/3">Ⓒ {{new Date().getFullYear()}} Cevi-Pfadi-Huus Verein <br>
Website by <a class="hover:underline" href="https://github.com/tobifra">Archimedes</a></div>
<div class="flex flex-row w-1/3 justify-end space-x-4">
<a href="https://www.cevi-volki.ch/"><img class="h-16" src="@/assets/cevi.png" alt=""></a>
<a href="https://wildert.ch"><img class="h-16" src="@/assets/wildert.png" alt=""></a>
</div>
  </div>
</template>

<script>
import ContactForm from "./components/ContactForm.vue";
import BigTitle from "./components/BigTitle.vue";
import SmallTitle from "./components/SmallTitle.vue";
import MenuButton from "./components/MenuButton.vue";
import MenuItem from "./components/MenuItem.vue";
import MobileMenu from "./components/MobileMenu.vue";
import DonationForm from "./components/DonationForm.vue";
import PeopleGrid from "./components/PeopleGrid.vue";
export default {
  components: { ContactForm, BigTitle, SmallTitle, PeopleGrid, MenuButton, MenuItem, MobileMenu, DonationForm },
  data() {
    return {
      menuStatus: false,
      
    };
  },
  methods: {
    handleMenuStatus(menuStatus){
      this.menuStatus = menuStatus
      console.log("test")
    }
  },
};
</script>

<style>
</style>