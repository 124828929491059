<template>
  <a :href="`#${href}`"><div class="text-lg font-light hover:text-gray-700 cursor-pointer"><slot></slot></div></a>
</template>

<script>
export default {
    props:["href"]

}
</script>

<style>

</style>