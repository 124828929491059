<template>
  <h1 class="text-4xl font-semibold mb-4 mt-2">
      <slot></slot>
    </h1>
</template>

<script>
export default {

}
</script>

<style>

</style>