<template>
  <div
    class="
      flex flex-col
      rounded-md
      px-3
      py-5
      bg-white bg-opacity-30
      items-center
    "
  >
    <img
      v-if="gender == 'm'"
      class="rounded-full h-20 w-20"
      src="@/assets/man_placeholder.jpg"
      alt=""
    />
    <img
      v-else
      src="@/assets/woman_placeholder.jpg"
      class="rounded-full h-20 w-20"
      alt=""
    />
    <p class="font-light text-xl">
      {{ name }}
    </p>
    <p class="font-light text-lg italic">
      {{ role }}
    </p>
    <a v-if="email" :href="`mailto:${email}`"
      ><div class="text-gray-500 hover:text-gray-700">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-7 w-7"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
          />
          <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
        </svg></div
    ></a>
    <div v-else class="h-7 w-7"></div>
  </div>
</template>

<script>
export default {
  props: ["gender", "name", "role", "email"],
};
</script>

<style>
</style>