<template>
<div class="relative">
  <button @click="handleMenuClick">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6 rounded-md hover:ring hover:ring-turkis-dark md:hidden"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="2"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M4 6h16M4 12h16M4 18h16"
      />
    </svg>
  </button>
  </div>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    emits:["menuStatus"],
    props: ["isOpen"],
    methods:{
        handleMenuClick(){
            this.$emit('menuStatus', !this.isOpen)


        }
    }
};
</script>

<style>
</style>