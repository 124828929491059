<template>
  <transition
    enter-active-class="transition duration-300 ease-out transform"
    enter-class="-translate-y-3 scale-95 opacity-0"
    enter-to-class="translate-y-0 scale-100 opacity-100"
    leave-active-class="transition duration-150 ease-in transform"
    leave-class="translate-y-0 opacity-100"
    leave-to-class="-translate-y-3 opacity-0"
  >
    <div v-if="isOpen" class="md:hidden sticky w-screen bg-turkis py-3">
      <div class="flex flex-col items-center w-full">
        <MenuItem @click="handleClick" href="">Home</MenuItem>
        <MenuItem @click="handleClick" href="zweck">Zweck</MenuItem>
        <MenuItem @click="handleClick" href="aktivitaeten"
          >Aktivitäten</MenuItem
        >
        <MenuItem @click="handleClick" href="vorstand">Vorstand</MenuItem>
        <MenuItem @click="handleClick" href="geschichte">Geschichte</MenuItem>
        <MenuItem @click="handleClick" href="kontakt">Kontakt</MenuItem>
        <MenuItem @click="handleClick" href="spenden"
          >Spenden oder Mitglied werden!</MenuItem
        >
      </div>
    </div>
  </transition>
</template>

<script>
import MenuItem from "./MenuItem.vue";
export default {
  props: ["isOpen"],
  emits: ["menuStatus"],
  components: { MenuItem },
  methods: {
    handleClick() {
      this.$emit("menuStatus", false);
    },
  },
};
</script>

<style>
</style>