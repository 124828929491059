<template>
  <div class="flex flex-row mt-2 space-x-3">
    <div class="flex space-x-2 items-center">
      <input
        id="donation"
        type="radio"
        @change="selectOption"
        :checked="isDonation"
      />
      <label for="donation" class="font-light">Spenden</label>
    </div>
    <div class="flex space-x-2 items-center">
      <input
        id="membership"
        type="radio"
        @change="selectOption"
        :checked="isMembership"
      />
      <label for="membership" class="font-light">Mitglied werden</label>
    </div>
  </div>
  <div class="rounded-lg bg-white bg-opacity-40 p-3 mt-2 flex flex-col">
    <div v-if="isMembership">
      <div class="flex space-x-2 items-center">
        <input
          id="singleMember"
          type="radio"
          @change="selectMemberType"
          :checked="isSingleMember"
        />
        <label for="singleMember" class="font-light"
          >Einzelmitglied (CHF 30)</label
        >
      </div>
      <div class="flex space-x-2 items-center">
        <input
          id="familyMember"
          type="radio"
          @change="selectMemberType"
          :checked="isFamilyMember"
        />
        <label for="familyMember" class="font-light"
          >Familienmitglied (CHF 40)</label
        >
      </div>
      <div class="flex space-x-2 items-center">
        <input
          id="companyMember"
          type="radio"
          @change="selectMemberType"
          :checked="isCompanyMember"
        />
        <label for="companyMember" class="font-light"
          >Firmenmitglied (CHF 60)</label
        >
      </div>
    </div>
    <div v-if="isDonation" class="flex flex-row items-stretch w-1/6">
        <div class="rounded-l-md
          focus:outline-none
          p-2
          w-1/3
          bg-white bg-opacity-40
          focus:ring focus:ring-gray-400 focus:ring-opacity-40
          mt-1">CHF</div>

      <input
        id="donation-field"
        type="number"
        v-model="donationSum"
        @input="changeDonationAmount"
        class="
          rounded-r-md
          focus:outline-none
          p-2
          w-2/3
          bg-white bg-opacity-40
          focus:ring focus:ring-gray-400 focus:ring-opacity-40
          mt-1
        "
      />
    </div>
  </div>
</template>

<script>
export default {
  emits: ["optionChange"],
  data() {
    return {
      isDonation: false,
      isMembership: true,
      isSingleMember: false,
      isFamilyMember: false,
      isCompanyMember: false,
      donationSum: 0
    };
  },
  methods: {
    selectOption(event) {
      if (event.target.id === "membership") {
        this.isMembership = true;
        this.isDonation = false;
      } else {
        this.isMembership = false;
        this.isDonation = true;
        this.$emit("optionChange",{membership: null, donation:true, donationSum: this.donationSum})
      }
    },
    selectMemberType(event) {
      switch (event.target.id) {
        case "singleMember":
          this.isSingleMember = true;
          this.isFamilyMember = false;
          this.isCompanyMember = false;
          this.$emit("optionChange",{membership: event.target.id, donation:false, donationSum: this.donationSum})
          break;
        case "familyMember":
          this.isSingleMember = false;
          this.isFamilyMember = true;
          this.isCompanyMember = false;
          this.$emit("optionChange",{membership: event.target.id, donation:false, donationSum: this.donationSum})
          break;
        case "companyMember":
          this.isSingleMember = false;
          this.isFamilyMember = false;
          this.isCompanyMember = true;
          this.$emit("optionChange",{membership: event.target.id, donation:false, donationSum: this.donationSum})
          break;
      }
    },
    changeDonationAmount(event){
      console.log("loooog")
      console.log(event.target.value)
      this.$emit("optionChange",{membership: null, donation:true, donationSum: this.donationSum})

    }
  },
};
</script>

<style>
</style>