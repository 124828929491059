<template>
<div class="w-full mt-2">
  <label :for="`${label}-field`" class="font-light">{{ label }}</label>
  <input
    :id="`${label}-field`"
    :type="type"
    v-model="input"
    class="
      rounded-md
      focus:outline-none
      p-2
      w-full
      bg-white bg-opacity-40
      focus:ring focus:ring-gray-400 focus:ring-opacity-40
      mt-1
    "
  />
  </div>
</template>

<script>
import { useModelWrapper } from "../utils/modelWrapper";
export default {
  props: ["modelValue", "label", "type"],
  setup(props, { emit }) {
    return {
      input: useModelWrapper(props, emit, "modelValue"),
    };
  },
};
</script>

<style>
</style>