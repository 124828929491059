<template>
  <div v-if="people" class="grid grid-cols-2 md:grid-cols-5 gap-3">
      <PersonCard
        v-for="person in people"
        :key="person.name"
        :gender="person.gender"
        :name="person.name"
        :role="person.role"
        :email="person.email"
      />
    </div>
</template>

<script>
import PersonCard from "./PersonCard.vue";
export default {
    components:{PersonCard},
    data(){
        return {
            people: undefined
        }
    },
    methods:{
        async getPeople(){
            try{
                const response = await this.axios.get(`${process.env.VUE_APP_API_PATH}/api/vorstand`)
                this.people = response.data
            }catch(e){
                console.log(e)
            }
        }
    },
    created(){
        this.getPeople();
        console.log(process.env.VUE_APP_API_PATH)
    }


}
</script>

<style>

</style>